import { graphql, navigate } from 'gatsby';

import DynamicZone from '../../../../components/dynamic-zone/dynamic-zone';
import FloatingButton from '../../../../components/floating-button/floating-button';
import Layout from '../../../../components/layout';
import React from 'react';

const PrivateContractsPage = (props) => {
    const components = props.data.cms.verifiedIdentityPageTemplate?.components || null;

    if (!components) {
        if (typeof window !== `undefined`) {
            navigate(`${props.pageContext.locale === `pl` ? `/pl` : ``}/404`);
        }
        return null;
    }

    return (
        <Layout location={props.location} locale={props.pageContext.locale}>
            <FloatingButton />
            <DynamicZone components={components} />
        </Layout>
    );
};

export default PrivateContractsPage;

export const query = graphql`
    query ($locale: String!) {
        cms {
            verifiedIdentityPageTemplate(locale: $locale) {
                components {
                    ... on STRAPI_ComponentComponentsHeader {
                        __typename
                        title
                        textBoxCustomClasses
                        headerSubtitle: subtitle
                        primaryImage {
                            url
                        }
                        mobileContentOffset
                        contentOffset
                        backgroundImageHeight
                        backgroundImage {
                            url
                        }
                    }
                    ... on STRAPI_ComponentComponentsHorizontalScroll {
                        __typename
                        topPusherHeightMobile
                        topPusherHeight
                        imagesTailwindClasses
                        sectionTitle {
                            tailwindClasses
                            ytLink
                            title
                            style
                            description
                        }
                        horizontalScrollColor
                        hasMobileGrayBackground
                        HorizontalScrollBenefits {
                            title
                            subtitle
                            image {
                                url
                            }
                        }
                    }
                    ... on STRAPI_ComponentHorizontalTabsHorizontalTabs {
                        __typename
                        topPusherHeightMobile
                        topPusherHeight
                        tabsDarkBackground
                        tabs {
                            type
                            title
                            subtitle
                            scrollColor
                            labelImage {
                                url
                            }
                            label
                            isLabelImageVisibleOnDesktop
                            imagesTitles
                            imagesMetadata
                            images {
                                url
                            }
                            fullWidthImageItemsDirection
                        }
                        tabHeight
                        sectionTitle {
                            tailwindClasses
                            title
                            ytLink
                            style
                            description
                        }
                    }
                    ... on STRAPI_ComponentComponentsBenefits {
                        __typename
                        topPusherHeightMobile
                        topPusherHeight
                        imagesTailwindClasses
                        sectionTitle {
                            tailwindClasses
                            ytLink
                            title
                            style
                            description
                        }
                        benefitsScrollColor
                        itemsToLeft
                        mobileItemsToLeft
                        hasDarkBackground
                        Benefit {
                            title
                            subtitle
                            image {
                                url
                            }
                        }
                    }
                    ... on STRAPI_ComponentUseCasesUseCasesSection {
                        __typename
                        topPusherHeightMobile
                        topPusherHeight
                        sectionTitle {
                            tailwindClasses
                            ytLink
                            style
                            title
                            description
                        }
                        UseCasesColumns {
                            title
                            subtitle
                            id
                            backgroundImage {
                                url
                            }
                            icon {
                                url
                            }
                        }
                    }
                    ... on STRAPI_ComponentVerticalTabsVerticalTabs {
                        __typename
                        topPusherHeightMobile
                        topPusherHeight
                        workOffer
                        sectionTitle {
                            title
                            style
                            description
                            ytLink
                            tailwindClasses
                            titleIcon {
                                url
                            }
                        }
                        VerticalTabTitle {
                            title
                            image {
                                url
                            }
                        }
                        VerticalTabContent {
                            titleImage {
                                url
                            }
                            title
                            contentTexts
                            contentRichText
                            contentImages {
                                url
                            }
                        }
                    }
                    ... on STRAPI_ComponentComponentsSingleImage {
                        __typename
                        singleImageContent
                        topPusherHeight
                        topPusherHeightMobile
                        image {
                            url
                        }
                    }
                    ... on STRAPI_ComponentSeoSeo {
                        __typename
                        seoDescription
                        seoLink
                        seoTitle
                        seoImages {
                            url
                        }
                    }
                }
            }
        }
    }
`;
